import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, filter, Observable, switchMap, take } from 'rxjs';
import { IAuthState, selectTokenData } from 'src/app/auth/store';
import { SocketService } from 'src/app/main/services/socket.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private _socketService: SocketService, private _storeAuth: Store<IAuthState>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([
      this._socketService.socketConnected$,
      this._storeAuth.select(selectTokenData)
    ]).pipe(
      filter(([isConnected, tokenData]) => {
        if (!isConnected && !tokenData) return true;
        else if (isConnected && tokenData) return true;
        return false;
      }),
      take(1),
      switchMap(() => {
        return next.handle(
          req.clone({
            headers: req.headers.set('app-name', 'intern')
          })
        );
      })
    );
  }
}
