import { Action, createReducer, on } from '@ngrx/store';
import { UserEnum } from 'src/app/main/utils';
import { getProfileDataComplete, setSelectedTheme } from '.';
import {
  profileReset,
  setExpansionPanel,
  setFabOpen,
  setFabSize,
  setMenuCollapse,
  setSelectedChatLayout,
  setThemeOptions
} from './profile.actions';
import * as fromProfileState from './profile.state';

const initialState: fromProfileState.IProfileState = {
  ...fromProfileState.profileState
};

const _profileReducer = createReducer(
  initialState,
  on(getProfileDataComplete, (state, data) => {
    const newData: Partial<fromProfileState.IProfileState> = { ...data };
    newData.pictureUrl =
      '/' + UserEnum.imageURL + (state._id ? state._id : data._id) + '?' + new Date().getTime();
    if (newData.picture) {
      delete newData.picture;
      newData.pictureUrl += '?' + new Date().getTime();
    }
    return { ...state, ...newData };
  }),
  on(setSelectedTheme, (state, { theme: payload }) => {
    return {
      ...state,
      selectedTheme: payload
    };
  }),
  on(setSelectedChatLayout, (state, { layout: payload }) => {
    return {
      ...state,
      chatLayout: payload
    };
  }),
  on(setFabSize, (state, { h, w }) => {
    return {
      ...state,
      fabSize: { h, w }
    };
  }),
  on(setFabOpen, (state, { open: payload }) => {
    return {
      ...state,
      fabOpen: payload
    };
  }),
  on(setExpansionPanel, (state, { id, expanded }) => {
    return {
      ...state,
      expansionPanels: {
        ...state.expansionPanels,
        [id]: expanded
      }
    };
  }),
  on(setMenuCollapse, (state) => {
    return {
      ...state,
      menuCollapse: state.menuCollapse !== undefined ? !state.menuCollapse : true
    };
  }),
  on(setThemeOptions, (state, { options }) => {
    return {
      ...state,
      themeOptions: {
        ...state.themeOptions,
        ...options
      }
    };
  }),
  on(profileReset, (state) => {
    return { ...initialState };
  })
);

export function profileReducer(
  state: fromProfileState.IProfileState,
  action: Action
): fromProfileState.IProfileState {
  return _profileReducer(state, action);
}
